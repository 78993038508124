
.v-application {
   font-family: 'Merriweather', serif !important;
}
div.v-input.theme--dark .v-input__slot {
	background-color: var(--v-primary-base) !important;
}
.even-item {
	background-color: #fafafa !important;
}
.odd-item {
	background-color: white !important;
}
