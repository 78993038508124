
.v-text-field_search[data-v-e35a4f50] {
    padding-top: 0;
    margin-top: 0;
}
.extras-list[data-v-e35a4f50] {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.extras-list > li > span[data-v-e35a4f50]:last-of-type {
    float: right;
    margin-left:10px
}
